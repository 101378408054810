import { Box, Button, Column, Flex, Grid } from "@twilio-paste/core";
import { useEffect, useState } from "react";


export default function HotelApp(props:any) {
    const [currentPage, setCurrentPage] = useState('menu');
    //props.analytics.page("hotel-app", "menu");
    

    function Navigate(page:string){
        setCurrentPage(page);
        
    }

    useEffect(()=>{
        props.analytics.page("hotel-app", currentPage);
    }, [currentPage, props.analytics]);

    function MainMenu() {
        return (
        <Box>
        <h1>Hotel App</h1>
        
        <h2>Fifth Quarter Hotel & Spa</h2>
        <Grid gutter="space20" equalColumnHeights marginBottom="space30">
            <Column>
                <Button variant="secondary" fullWidth={true} onClick={() => Navigate('gym-swim-spa')}>
                    Gym, Swim <br />& Spa
                </Button>
            </Column>
            <Column>
                <Button variant="secondary" fullWidth={true} onClick={() => Navigate('food-drink')}>
                    Food <br />& Drink
                </Button>
            </Column>
            <Column>
                <Button variant="secondary" fullWidth={true} onClick={() => Navigate('my-stay')}>
                    My Stay
                </Button>
            </Column>
        </Grid>
        <Grid gutter="space20" equalColumnHeights marginBottom="space30">
            <Column>
                <Button variant="secondary" fullWidth={true} onClick={() => Navigate('digital-key')}>
                    Digital Key
                </Button>
            </Column>
            <Column>
                <Button variant="secondary" fullWidth={true} onClick={() => Navigate('room-controls')}>
                    Room Controls
                </Button>
            </Column>
            <Column>
                <Button variant="secondary" fullWidth={true} onClick={() => Navigate('invoice')}>
                    Invoice
                </Button>
            </Column>
        </Grid>
        <Grid gutter="space20" equalColumnHeights>
            <Column>
                <Button variant="secondary" fullWidth={true} onClick={() => Navigate('concierge')}>
                    Concierge
                </Button>
            </Column>
            <Column>
                <Button variant="secondary" fullWidth={true} onClick={() => Navigate('nearby')}>
                    Nearby
                </Button>
            </Column>
            <Column>
                <Button variant="secondary" fullWidth={true} onClick={() => Navigate('check-out')}>
                    Check<br /> Out
                </Button>
            </Column>
        </Grid> 
        </Box>
        );
    }

    function GymSwim() {
        return (
            <Box>
                <h1>Gym, Swim & Spa</h1>
                <h2>Gym</h2>
                <p>The gym is available for guests to use 24h a day.</p>
                <p>Towels, lockers, hairdryers etc are all provided in the changing area.</p>
                <p>You can find the gym on floor 4, please use your room key or the mobile app to gain access to the gym.</p>
                {props.device 
                    ? <Button variant="secondary" onClick={() => props.makeCall("hotel-app", "room-124:gym-enquiry")}>Call</Button> 
                    : <Button variant="secondary" disabled>Call</Button> 
                }
               

                
                <h2>Swim</h2>
                <p>The pool is open from 6am through to 9pm.</p>
                <p>Towels, lockers, hairdryers etc are all provided in the changing area.</p>
                <p>Access to the pool is via the changing facilities on floor 4. You can use your room key or the mobile app to gain access.</p>
                <h2>Spa</h2>
                <h3>Thermal Circuit</h3>
                <p>The thermal circuit, including ice pool, sauna, steam room and hydrotherapy pods, are all available in the Spa.</p>
                <p>The spa is located on level B1. We would recommend booking a 2 hour time slot with us to avoid disappointment.</p>
                <h3>Treatments</h3>
                <p>Massage treatments etc are available in our spa facility. This is by prior appointment only. Please contact the spa for more details.</p>
            </Box>
        );
    }

    function FoodDrink(){
        return (
            <Box>
                <h1>Food & Drink</h1>
                <h2>Restaurant</h2>
                <h2>Room Service</h2>
            </Box>
        );
    }

    return (
    <Flex>
        <Flex padding="space20">
            <Box width="400px" backgroundColor="colorBackgroundBody" padding="space40">
            {currentPage === "menu" && 
                <MainMenu />
            }

            {currentPage !== "menu" && 
                <Button variant="inverse" fullWidth={false} onClick={() => Navigate('menu')}>
                Back
                </Button>
            }
        
            {currentPage === "gym-swim-spa" && 
                <GymSwim />
            }

            {currentPage === "food-drink" && 
                <FoodDrink />
            } 
            </Box>
        </Flex>        
    </Flex>);

}



