import {Theme} from '@twilio-paste/core/theme'
import {Anchor, Box, Button, Flex, Stack} from '@twilio-paste/core'
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'
import { useEffect, useState } from 'react';
import Home from './routes/home';
import Bulk from './routes/bulk';
import Delivery from './routes/delivery';
import SoftPhone from './components/SoftPhone';
import Queues from './components/Queues';
import Queue from './components/Queue';
import HotelHome from './components/HotelDemo/HotelHome';
import ViewBookings from './components/HotelDemo/HotelManager/ViewBookings';
import HotelApp from './components/HotelDemo/HotelApp';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { Call, Device } from '@twilio/voice-sdk';



function App() {


  const [user, setUser] = useState<null | {userDetails: any}>(null);

  
  const analytics = AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY! })
  

  // PhoneCall State 
  const [isLoading, setIsLoading] = useState(false);
  const [currentCall, setCurrentCall] = useState<Call>();
  const [currentCallIsMuted, setCurrentCallIsMuted] = useState<boolean>(false);
  const [device, setDevice] = useState<Device>();
  const [pendingIncomingCall, setPendingIncomingCall] = useState<Call>();
  // End of PhoneCall State


  const makeCall = async (demo:string, topic:string) => {
    if(device){
        var call = await device.connect({ params: { To: "+447471473366", Demo: demo, Topic: topic }});

        call.on("accept", () => {
            setCurrentCall(call);
            setCurrentCallIsMuted(false);
        });

        call.on("disconnect", () => {
            setCurrentCall(undefined);
            setCurrentCallIsMuted(false);
        });
    }
}


  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if(!analytics) return;

    if(user){
      analytics.identify(user.userDetails);
    }
  }, [user, analytics])

  async function getUserInfo() {
    try {
      const response = await fetch('/.auth/me');
      const payload = await response.json();
      const { clientPrincipal } = payload;
    
      if(clientPrincipal){
        setUser(clientPrincipal);
      }
    }
    catch(error:any){
      console.error('No profile could be found ' + error?.message?.toString());
    }
  };


  


  return (
    <Theme.Provider theme="default">
      
      {!user && 
        <>
          <Button as="a" href="/.auth/login/google/?post_login_redirect_uri=/" variant="primary">
            Login with Google
          </Button>
        </>
      }

      {user && 
      <>
        <Box>
        Signed In as {user.userDetails} 
        <Anchor href={`/.auth/logout/?post_logout_redirect_uri=/`}>Logout</Anchor>
        </Box>
        <SoftPhone 
          makeCall={makeCall}
          isLoading={isLoading} 
          setIsLoading={setIsLoading}  
          currentCall={currentCall} 
          setCurrentCall={setCurrentCall} 
          currentCallIsMuted={currentCallIsMuted} 
          setCurrentCallIsMuted={setCurrentCallIsMuted}
          device={device} 
          setDevice={setDevice}
          pendingIncomingCall={pendingIncomingCall}
          setPendingIncomingCall={setPendingIncomingCall} />
        <Router>

        <Flex>
          <Flex>
            <Box backgroundColor="colorBackgroundPrimaryWeak" padding="space40" width="150px">
              <Stack orientation="vertical" spacing="space20">
                <Link to="/">Home</Link>
                <Link to="/bulk">Bulk</Link>
                <Link to="/delivery">Delivery</Link>
                <Link to="/queues">Queues</Link>
                <Link to="/hotel">Hotel</Link>
                <Link to="/hotelManager/">Hotel Manager</Link>
              </Stack>      
            </Box>
            </Flex>
          <Flex grow>
            <Box 
              backgroundColor="colorBackgroundPrimaryWeaker" 
              padding="space40"
              width="100%"
            >
                      <Box margin="space50">
        <div>


        

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          
          {user && (<>
            <Route path="/bulk" element={<Bulk />} />
            <Route path="/delivery" element={<Delivery />} />
            <Route path="/queue/:sid" element={<Queue />} />
            <Route path="/queues" element={<Queues /> } />
            <Route path="/hotel/app" element={<HotelApp makeCall={makeCall} device={device} analytics={analytics} /> } />
            <Route path="/hotel" element={<HotelHome /> } />
            <Route path="/hotelManager" element={<ViewBookings /> } />
          </>
          )}
          
          <Route path="/" element={<Home />} />
          
        </Routes>
      </div>
            </Box>
            </Box>
          </Flex>
        </Flex>
        </Router>
        

    

      </>
    }



      
    </Theme.Provider>
    
  );
}

export default App;
