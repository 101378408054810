
import { Box, Button, Callout, CalloutHeading, Stack } from "@twilio-paste/core"
import { Call } from "@twilio/voice-sdk";

type Props = {
    pendingIncomingCall: Call;
    onAcceptCall: () => void;
    onRejectCall: () => void;
}

const SoftPhoneIncomingCallControls: React.FC<Props> = ({
    pendingIncomingCall, 
    onAcceptCall, 
    onRejectCall 
}) => {
    return (
        <Box margin='space20'>
            <Callout variant="neutral">
                <CalloutHeading as="h3">Incoming Call from {pendingIncomingCall.parameters.From}</CalloutHeading>
                <Stack orientation="horizontal" spacing="space30">
                        <Button variant="primary" onClick={() => onAcceptCall() }>Accept</Button>
                        <Button variant="destructive" onClick={() => onRejectCall() }>Reject</Button>
                    </Stack>
            </Callout>                            
        </Box>
    );
};
  
export default SoftPhoneIncomingCallControls;


