import DeliveryForm from "../components/DeliveryForm"


export default function Delivery() {
    return (
    <>
        <h1>Delivery Tracking Demo</h1>
        <DeliveryForm />
    </>);

}