import { Anchor, Box, Heading, SkeletonLoader, Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core";
import { useEffect, useState } from "react";

export default function Queues() {

    const [queueList, setQueueList] = useState<any[]>();

    

    useEffect(() => {

        async function getQueues() {
            const response = await fetch('/api/Get_Twilio_Queues');
            const payload = await response.json();
            setQueueList(payload.queues);
        }

        getQueues();
    }, []);

    return (
        <Box>
            <Heading variant="heading10" as="h1">Queues</Heading>

            <Table>
                <THead>
                    <Tr>
                        <Th>SID</Th>
                        <Th>Friendly Name</Th>
                        <Th>Current Size</Th>
                        <Th>Average Wait</Th>
                        <Th>Max Size</Th>
                    </Tr>
                </THead>
                    {queueList && 
                    <TBody>
                        {queueList.map((queue) => 
                        <Tr key={queue.sid}>
                            <Td><Anchor href={"/queue/" + queue.sid}>{queue.sid}</Anchor></Td>
                            <Td>{queue.friendlyName}</Td>
                            <Td>{queue.currentSize}</Td>
                            <Td>{queue.averageWaitTime}</Td>
                            <Td>{queue.maxSize}</Td>
                            </Tr>)}
                        </TBody>
                    }

                    {!queueList && 
                        <TBody>
                            <Tr>
                                <Td><SkeletonLoader /></Td>
                                <Td><SkeletonLoader /></Td>
                                <Td><SkeletonLoader /></Td>
                                <Td><SkeletonLoader /></Td>
                                <Td><SkeletonLoader /></Td>
                            </Tr>
                            <Tr>
                                <Td><SkeletonLoader /></Td>
                                <Td><SkeletonLoader /></Td>
                                <Td><SkeletonLoader /></Td>
                                <Td><SkeletonLoader /></Td>
                                <Td><SkeletonLoader /></Td>
                            </Tr>
                            <Tr>
                                <Td><SkeletonLoader /></Td>
                                <Td><SkeletonLoader /></Td>
                                <Td><SkeletonLoader /></Td>
                                <Td><SkeletonLoader /></Td>
                                <Td><SkeletonLoader /></Td>
                            </Tr>
                        </TBody>
                    }              
            </Table>

        </Box>
    );
} 