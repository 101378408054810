import { Box, Stack, Heading, SkeletonLoader, Text } from "@twilio-paste/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Queue() {

    const [queueDetails, setQueueDetails] = useState<any>();
    const {sid} = useParams();

   

    useEffect(() => {


        async function getQueue() {
            const response = await fetch('/api/Get_Twilio_Queue/' + sid);
            const payload = await response.json();
            setQueueDetails(payload.queue);
        }

        getQueue();
    }, [sid]);


    return (
        
        <Box>
            <Heading variant="heading20" as="h2">Queue Details</Heading>

            <Stack spacing="space40" orientation="vertical" as="dl">
                <Text as="dt" paddingTop="space40" fontWeight="fontWeightBold">Queue SID</Text>
                {queueDetails && <Text as="dd">{queueDetails.sid}</Text>}
                {!queueDetails && <SkeletonLoader />}  
               
                <Text as="dt" paddingTop="space40" marginBottom="space20" fontWeight="fontWeightBold">Queue FriendlyName</Text>
                {queueDetails && <Text as="dd">{queueDetails.friendlyName}</Text>}
                {!queueDetails && <SkeletonLoader />}  
            </Stack>
        </Box>
    );
} 