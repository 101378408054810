import HotelBookingForm from "./BookRoom";


export default function HotelHome() {
    return (
    <>
        <HotelBookingForm />
    </>);

}



