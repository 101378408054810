
import { Badge, Box, Button, Callout, CalloutHeading, Stack } from "@twilio-paste/core"
import { Call } from "@twilio/voice-sdk";

type Props = {
    currentCall: Call;
    currentCallIsMuted: boolean;
    hangupCall: () => void;
    setIsMuted: (setIsMuted: boolean) => void;
}

const SoftPhoneInProgressCallControls: React.FC<Props> = ({
    currentCall, 
    currentCallIsMuted,
    hangupCall,
    setIsMuted
}) => {
    return (
        <Box>
            <Callout variant="warning">
                <CalloutHeading as="h3">Call In Progress</CalloutHeading>
                <Stack orientation="horizontal" spacing="space30">
                        <Badge as="span" variant="neutral">{currentCall.direction}</Badge>

                        <Button variant="destructive" onClick={() => hangupCall() }>Hang up</Button>
                        {!currentCallIsMuted && 
                            <Button variant="secondary" onClick={() => setIsMuted(true) }>Mute</Button>
                        }

                        {currentCallIsMuted && 
                            <Button variant="secondary" onClick={() => setIsMuted(false) }>Unmute</Button>
                        }
                    
                    </Stack>
            </Callout>
        </Box>
    );
};
  
export default SoftPhoneInProgressCallControls;


