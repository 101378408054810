import { Button, DataGrid, DataGridBody, DataGridCell, DataGridHead, DataGridHeader, DataGridRow, Flex, Spinner } from "@twilio-paste/core";
import { useEffect, useState } from "react";
import BookingDetails from "./BookingDetails";

export default function ViewBookings() {

    const [records, setRecords] = useState<any[]>();
    const [selectedRecord, setSelectedRecord] = useState<any>();

    useEffect(() => {
        async function fetchBookings() {
            const rawResponse = await fetch('/api/HotelManager-GetBookings/', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
            const json = await rawResponse.json();
            setRecords(json.bookings);

        }

        fetchBookings();

    }, []);



    console.log(records);
    return (

        <>


            {!records &&
                <Spinner size="sizeIcon110" delay={0} decorative={false} title="Loading" />
            }

            {records &&
                <>

                    {!selectedRecord && <>
                        <h2>Upcoming Bookings</h2>
                        <DataGrid aria-label="Upcoming booking table" striped>
                            <DataGridHead>
                                <DataGridRow>
                                    <DataGridHeader>Booking Id</DataGridHeader>
                                    <DataGridHeader>Check-In Date</DataGridHeader>
                                    <DataGridHeader>Number of Nights</DataGridHeader>
                                    <DataGridHeader>Hotel</DataGridHeader>
                                    <DataGridHeader>Membership Level</DataGridHeader>
                                    <DataGridHeader>Contact</DataGridHeader>
                                    <DataGridHeader></DataGridHeader>
                                </DataGridRow>
                            </DataGridHead>
                            <DataGridBody>
                                {records.map((row, rowIndex) => (
                                    <DataGridRow key={"row-" + rowIndex}>
                                        <DataGridCell key={"cell-" + rowIndex + "-1"}>{row.id}</DataGridCell>
                                        <DataGridCell key={"cell-" + rowIndex + "-2"}>{row.originatingRequest.check_in_date}</DataGridCell>
                                        <DataGridCell key={"cell-" + rowIndex + "-3"}>{row.originatingRequest.number_of_nights}</DataGridCell>
                                        <DataGridCell key={"cell-" + rowIndex + "-4"}>{row.originatingRequest.hotel}</DataGridCell>
                                        <DataGridCell key={"cell-" + rowIndex + "-5"}>{row.originatingRequest.membershipLevel}</DataGridCell>
                                        <DataGridCell key={"cell-" + rowIndex + "-6"}>{row.originatingRequest.phone_number}<br />{row.originatingRequest.email_address}</DataGridCell>
                                        <DataGridCell key={"cell-" + rowIndex + "-btns"}>
                                            <Button variant="secondary" onClick={() => setSelectedRecord(row)}>Details</Button>
                                        </DataGridCell>
                                    </DataGridRow>
                                ))}
                            </DataGridBody>
                        </DataGrid></>}

                    {selectedRecord &&
                        <>
                            <Flex>
                                <Button variant="secondary" onClick={() => setSelectedRecord(null)}>Back to Bookings</Button>
                            </Flex>
                            <BookingDetails booking={selectedRecord} />

                        </>
                    }


                </>
            }




        </>


    );
}