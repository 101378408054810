import { Box, Button, HelpText, Input, Label, Toaster, useToaster } from "@twilio-paste/core";
import { useState } from "react";
import { useForm } from 'react-hook-form'

export default function DeliveryForm() {

    const [stage, setStage] = useState('initial');
    const [deliveryId, setDeliveryId] = useState(null);

    const toaster = useToaster();
    const { register, handleSubmit, formState: { errors, isSubmitting }} = useForm({ defaultValues: {email_address: "", phone_number: ""}});

    async function createDeliveryRecord(data:any){

        toaster.push({
            message: "Processing Order...",
            variant: 'neutral'
        });

        const rawResponse = await fetch('/api/Delivery', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...data})
        });
        
        const content = await rawResponse.json();
        
        if(rawResponse.status !== 200){
            toaster.push({variant: 'error', message: "Failed: " + content.message});
        }else{
            toaster.push({dismissAfter: 4000, variant: 'success', message: "Success!"});
            setStage('awaitingDespatch');
            setDeliveryId(content.cosmos.id);
        }
    }

    async function markAsDespatched(){
        toaster.push({message: "Sending Despatch Notification", variant: 'neutral'});

        const rawResponse = await fetch('/api/Delivery', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ executionId: deliveryId, stage: "despatch" })
        });

        const content = await rawResponse.json();
        if(rawResponse.status !== 200){
            toaster.push({variant: 'error', message: "Notification Failed: " + content.message});
        }else{
            toaster.push({dismissAfter: 4000, variant: 'success', message: "Notification Sent"});
            setStage('deliveryToday');
        }
    }

    async function markAsDriverNearby(){
        toaster.push({message: "Sending Driver Nearby Notification", variant: 'neutral'});
        const rawResponse = await fetch('/api/Delivery', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ executionId: deliveryId, stage: "driverNearby" })
        });

        const content = await rawResponse.json();
        if(rawResponse.status !== 200){
            toaster.push({variant: 'error', message: "Notification Failed: " + content.message});
        }else{
            toaster.push({dismissAfter: 4000, variant: 'success', message: "Notification Sent"});
            setStage('deliveryNearby');
        }
    }

    async function markAsDeliveryComplete(){
        toaster.push({message: "Sending Delivery Complete Notification", variant: 'neutral'});
        const rawResponse = await fetch('/api/Delivery', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ executionId: deliveryId, stage: "deliveryComplete" })
        });

        const content = await rawResponse.json();
        if(rawResponse.status !== 200){
            toaster.push({variant: 'error', message: "Notification Failed: " + content.message});
        }else{
            toaster.push({dismissAfter: 4000, variant: 'success', message: "Notification Sent"});
            setStage('deliveryComplete');
        }
    }



    function InitialForm(){
        return (
            <form onSubmit={handleSubmit(createDeliveryRecord)}>
            <Box marginBottom="space80">
                <Label htmlFor="email_address" required>Email address</Label>
                <Input 
                    {...register("email_address", {required: "Email address must be provided"})}
                    aria-describedby="email_help_text" 
                    id="email_address" 
                    name="email_address" 
                    type="email" 
                    placeholder="example@twilio.com" 
                     />
                
                {errors.email_address && 
                    <HelpText id="email_error_help_text" variant="error">{errors.email_address.message}</HelpText>
                }
                <HelpText id="email_help_text">Email address to send delivery updates to.</HelpText>
            </Box>
            <Box marginBottom="space80">
                <Label htmlFor="phone_number" required>Mobile Telephone Number</Label>
                <Input 
                    {
                        ...register("phone_number", {required:"Mobile phone number must be provided", 
                    minLength: { value: 8, message: "Must be between 8 and 20 digits"}, 
                    maxLength: { value: 20, message: "Must be between 8 and 20 digits"}})}

                    aria-describedby="email_help_text" 
                    id="phone_number" 
                    name="phone_number" 
                    type="tel" 
                    placeholder="+44" 
                     />
                {errors.phone_number && 
                    <HelpText id="phone_error_help_text" variant="error">{errors.phone_number.message}</HelpText>
                }
                <HelpText id="phone_number_help_text">Mobile phone number to send delivery updates to. (E.164 format please)</HelpText>
            </Box>
            

            <Button variant="primary" type="submit" loading={isSubmitting}>Submit</Button>
            
        </form>

        );
    }

    function AwaitingDespatch(){
        return (
            <>
                <p>Order currently pending despatch. </p>
                <Button variant="primary" onClick={() => markAsDespatched() }>Mark as Despatched</Button>
            </>
        );
    }

    function DeliveryToday(){
        return (
            <>
                <p>Order currently out for delivery.</p>
                <Button variant="primary" onClick={() => markAsDriverNearby() }>Driver Nearby</Button>
            </>
        );
    }


    function DeliveryNearby(){
        return (
            <>
                <p>Customer has been notified that the delivery is nearby.</p>
                <Button variant="primary" onClick={() => markAsDeliveryComplete() }>Delivery Complete</Button>
            </>
        )
    }

    function DeliveryComplete() {
        return (
            <>
                <p>Customer has been informed that the delivery is completed!</p>
                <Button variant="destructive" onClick={() => { setStage('initial'); setDeliveryId(null); }}>Reset</Button>
            </>
        )
    }

    return (

        <>
            <div>{deliveryId}</div>
            {stage === 'initial' && <InitialForm />}
            {stage === 'awaitingDespatch' && <AwaitingDespatch />}
            {stage === 'deliveryToday' && <DeliveryToday />}
            {stage === 'deliveryNearby' && <DeliveryNearby />}
            {stage === 'deliveryComplete' && <DeliveryComplete />}

            <Toaster {...toaster} />
        </>
    );
}