import { Box, Button, Stack } from "@twilio-paste/core";
import { Device } from '@twilio/voice-sdk';
import SoftPhoneIncomingCallControls from "./SoftPhoneIncomingCallControls";
import SoftPhoneInProgressCallControls from "./SoftPhoneInProgressCallControls";

export default function SoftPhone(props: any) {

   
    console.log(props);    

    const getToken = async() => {
        const rawResponse = await fetch('/api/GetToken', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        
        const content = await rawResponse.json();
        return content;
    }

    const initializeClient = async () => {
        props.setIsLoading(true);

        var content = await getToken();

        if(!content.success)
            return; 
        
        const device = new Device(content.token);
        
        device.on('tokenWillExpire', async() => {
            const token = await getToken();
            if(token.success){
                device.updateToken(token);
            }
        });

        device.on('registered', () => {
            props.setDevice(device);
            props.setIsLoading(false);
        });

        device.on('incoming', call => {
            call.on('cancel', () => {
                props.setPendingIncomingCall(undefined);
            });

            console.log(call);
            props.setPendingIncomingCall(call);
        });

        device.register();
    }

    const disableVoiceClient = async() => {
        props.setIsLoading(false);
        props.setCurrentCallIsMuted(false);
        props.device?.destroy();
        props.setDevice(undefined);
    }

    

    const acceptCall = async() => {
        if(!props.pendingIncomingCall)
            return; 
        
            props.pendingIncomingCall.on("accept", () => {
                props.setCurrentCallIsMuted(false);
        });

        props.pendingIncomingCall.on("disconnect", () => {
            props.setCurrentCall(undefined);
            props.setCurrentCallIsMuted(false);
        });

        props.pendingIncomingCall.accept();      
        props.setCurrentCall(props.pendingIncomingCall);  
        props.setPendingIncomingCall(undefined);
    }

    const rejectCall = async() => {
        if(!props.pendingIncomingCall)
            return; 

        props.pendingIncomingCall.reject();
        props.pendingIncomingCall.disconnect();
        props.setPendingIncomingCall(undefined);
    }

    const hangupCall = async() => {
        props.currentCall?.disconnect();
        props.setCurrentCall(undefined);
        props.setCurrentCallIsMuted(false);
    }

    const setIsMuted = async(shouldMute:boolean) => {
        props.currentCall?.mute(shouldMute);
        props.setCurrentCallIsMuted(shouldMute);
    }
    
    var canMakeCallOrDisconnect = !props.currentCall && !props.pendingIncomingCall;
    return (
    <>
        <Box margin="space50">
            {props.device && 
                <Box>
                    <Box margin='space20'>
                        <Stack orientation="horizontal" spacing="space30">
                            <Button variant="secondary" disabled={!canMakeCallOrDisconnect} onClick={() => disableVoiceClient() }>Disable Voice Client (Logged into Voice as client:{props.device?.identity})</Button>
                            <Button variant="primary" disabled={!canMakeCallOrDisconnect} onClick={() => props.makeCall("generic", "none") }>Make Call</Button>
                        </Stack>
                    </Box>
                    
                    {props.pendingIncomingCall && 
                        <SoftPhoneIncomingCallControls 
                            pendingIncomingCall={props.pendingIncomingCall} 
                            onAcceptCall={acceptCall} 
                            onRejectCall={rejectCall} 
                        />
                    }

                    {props.currentCall && 
                        <SoftPhoneInProgressCallControls 
                            currentCall={props.currentCall} 
                            currentCallIsMuted={props.currentCallIsMuted} 
                            hangupCall={hangupCall} 
                            setIsMuted={(isMuted:boolean) => { setIsMuted(isMuted)}}                           
                        />
                    }
                </Box>
                
            }
            {!props.device && <Button variant="secondary" disabled={props.isLoading} onClick={() => initializeClient()}>Enable Voice Client</Button>}
            
            
        </Box>
    </>);
}
