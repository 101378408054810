
import { Box, Button, DatePicker, HelpText, Input, Label, Select, Option, Toaster, useToaster, CodeBlock, Flex } from "@twilio-paste/core";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form'
import * as Ably from 'ably';

export default function HotelBookingForm() {
    
    const toaster = useToaster();
    const { register, handleSubmit, formState: { errors, isSubmitting }} 
        = useForm({ defaultValues: { 
            email_address: "", 
            phone_number: "", 
            check_in_date: new Date(),
            number_of_nights: 1,
            hotel: 'Owl Hootel - Soho Square - London',
            membershipLevel: 'Diamond VIP'
        }});
    
    const [stage, setStage] = useState('initial');
    const [bookingId, setBookingId] = useState(null);
    const [cosmosRecord, setCosmosRecord] = useState(null);


    useEffect(() => {
        var client: any;
        var channel: Ably.Types.RealtimeChannelCallbacks | undefined;
        if(bookingId){
            const fetchData = async() => {
                const rawResponse = await fetch('/api/HotelBooking/' + bookingId, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
                const json = await rawResponse.json();
                setCosmosRecord(json.booking);
                
                client = new Ably.Realtime({
                    authCallback: async (tokenParams, callback) => {
                        try {
                            const rawResponse = await fetch('/api/GetAblyToken?entity=' + bookingId, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                            });
                            const tokenResponse = await rawResponse.json();
                            callback(null, tokenResponse);
                        } catch (error: any){
                            console.log(error);
                            callback(error, null);
                        }
                    }
                }); /* inferred type Ably.Realtime */
                
                client.connection.once("connected", () => {
                    const user = client.auth.tokenDetails.clientId || "anonymous";
                    const capability = client.auth.tokenDetails.capability;
                    console.log(
                      `You are now connected to Ably \nUser: ${user}\nCapabilities: ${capability}`
                    );
                  });
                
                channel = client.channels.get(bookingId); /* inferred type Ably.Types.RealtimeChannel */
    
                if(channel) {
                    channel.subscribe(function(message) {
                        setCosmosRecord(message.data);
                    });
                }
                

            }
            fetchData();
        }
        return () => { 
            if(channel) { 
                console.log('closing realtime channel');
                channel.unsubscribe(); 
            } 
            if(client) { 
                console.log('tidying up realtime client');
                client.close(); }
            } 
    },[bookingId]);


    
    async function createBooking(data:any){
       
        console.log(data);
        toaster.push({
            message: "Processing Order...",
            variant: 'neutral'
        });

        const rawResponse = await fetch('/api/HotelBooking', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...data})
        });
        
        const content = await rawResponse.json();
        
        if(rawResponse.status !== 200){
            toaster.push({variant: 'error', message: "Failed: " + content.message});
        }else{
            toaster.push({dismissAfter: 4000, variant: 'success', message: "Success!"});
            setStage('booked');
            setBookingId(content.cosmos.id);
        }
    }

    
    
    function InitialForm(){
        return (
            <form onSubmit={handleSubmit(createBooking)} style={{maxWidth: "400px"}}>
            <h2>Book a room</h2>
            <Box marginBottom="space80">
                <Label htmlFor="check_in_date" required>Check-In Date</Label>
                <DatePicker {...register("check_in_date", {required: "Check in Date is Required" })} />
            </Box>

            <Box marginBottom="space80">
                <Label htmlFor="number_of_nights" required>Number of Nights</Label>
                <Select {...register("number_of_nights")}>
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                    <Option value="6">6</Option>
                    <Option value="7">7</Option>
                </Select>
            </Box>

            <Box marginBottom="space80">
                <Label htmlFor="email_address" required>Email address</Label>
                <Input 
                    {...register("email_address", {required: "Email address must be provided"})}
                    aria-describedby="email_help_text" 
                    id="email_address" 
                    name="email_address" 
                    type="email" 
                    placeholder="example@twilio.com" 
                     />
                
                {errors.email_address && 
                    <HelpText id="email_error_help_text" variant="error">{errors.email_address.message}</HelpText>
                }
                <HelpText id="email_help_text">Email address to send booking updates to.</HelpText>
            </Box>
            <Box marginBottom="space80">
                <Label htmlFor="phone_number" required>Mobile Telephone Number</Label>
                <Input 
                    {
                        ...register("phone_number", {required:"Mobile phone number must be provided", 
                    minLength: { value: 8, message: "Must be between 8 and 20 digits"}, 
                    maxLength: { value: 20, message: "Must be between 8 and 20 digits"}})}

                    aria-describedby="email_help_text" 
                    id="phone_number" 
                    name="phone_number" 
                    type="tel" 
                    placeholder="+44" 
                     />
                {errors.phone_number && 
                    <HelpText id="phone_error_help_text" variant="error">{errors.phone_number.message}</HelpText>
                }
                <HelpText id="phone_number_help_text">Mobile phone number to send delivery updates to, and for hotel staff to get in touch if needed. (E.164 format please)</HelpText>
            </Box>
            

            <Button variant="primary" type="submit" loading={isSubmitting}>Submit</Button>
            
        </form>

        );
    }
    
    function HotelConfirmation() {
        return (
        <>
            <h2>Hotel Confirmed</h2>
            <h3>Booking Reference: {bookingId}</h3>
        </>);
    }
    
    
    
    return (
    <>
        
        
        {stage === 'initial' && <InitialForm />}
        {stage === 'booked' && (
            <Flex>
                <Flex grow>
                    <Box padding="space40">
                        <HotelConfirmation />
                    </Box>
                </Flex>
                <Flex width="700px">
                    {cosmosRecord && 
                    <Box padding="space40">
                        <CodeBlock  wrapLines={true} variant="multi-line" code={ JSON.stringify(cosmosRecord, null, 2)} language="javascript" />
                    </Box>
                    }
                </Flex>
            </Flex>    
        )
        
        }


        <Toaster {...toaster} />

        
    </>
    );

}





