import { Anchor, Card, Flex, Heading, Paragraph } from "@twilio-paste/core"

export default function Home() {

    type CardProps = {
        name: string;
        description: string;
        active: boolean;
        link: string;
    }

    const DemoCard = ({ name, description, link, active } : CardProps) => (
        <Card padding="space120">
            <Heading as="h2" variant="heading20">{name}</Heading>
            <Paragraph>{description}</Paragraph>
            {active && <Paragraph><Anchor href={link}>Take me there!</Anchor></Paragraph> }
            {!active && <Paragraph >Coming Soon</Paragraph>} 
            
        </Card>
    )

    return <>
        <Flex>
            <Flex grow shrink basis="1px">
                <DemoCard
                    name="Delivery Demo" 
                    description="Delivery notifications flow"
                    link="/delivery"
                    active={true}
                />
            </Flex>
            <Flex grow shrink basis="1px">
                <DemoCard 
                    name="Hotel Demo"
                    description="Hotel 'Super App' Ideas"
                    link="/hotel"
                    active={true}
                />
            </Flex>
            <Flex grow shrink basis="1px">
                <DemoCard 
                    name="Food App Demo"
                    description="Food Ordering &amp; Delivery Service" 
                    link="/foodapp"
                    active={false}
                />
            </Flex>
        </Flex>
        
    </>
}