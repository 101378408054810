import { Box, ChatBubble, ChatEvent, ChatLog, ChatMessage, ChatMessageMeta, ChatMessageMetaItem, Flex, Stack, Text } from "@twilio-paste/core";

export default function BookingDetails(props: any) {

    let messages: any[] = props.booking.messages;




    console.log(messages);

    // we want to assosiate any webhooks with this parent here.
    messages.forEach((message) => {
        if (message.type === "twilio-new-message") {
            // Look for associated webhook responses

            message.sent = messages.find(element => 
                element.type === "twilio-status-webhook" &&
                element.details &&
                element.details.MessageSid === message.details.sid &&
                element.details.SmsStatus === "sent");

            message.delivered = messages.find(element => 
                element.type === "twilio-status-webhook" &&
                element.details &&
                element.details.MessageSid === message.details.sid &&
                element.details.SmsStatus === "delivered");
        }
    });


    return (
        <>

            <Flex>

                <Flex grow>
                    <Stack spacing="space40" orientation="vertical">
                        <h2>Booking Details</h2>
                        <Box as="dl">
                            <Text as="dt" fontWeight="fontWeightSemibold">Booking Id</Text>
                            <Text as="dd">{props.booking.id}</Text>

                            <Text marginTop="space60" as="dt" fontWeight="fontWeightSemibold">Hotel</Text>
                            <Text as="dd">{props.booking.originatingRequest.hotel}</Text>

                            <Text marginTop="space60" as="dt" fontWeight="fontWeightSemibold">Check-In Date</Text>
                            <Text as="dd">{props.booking.originatingRequest.check_in_date}</Text>

                            <Text marginTop="space60" as="dt" fontWeight="fontWeightSemibold">Number of Nights</Text>
                            <Text as="dd">{props.booking.originatingRequest.number_of_nights}</Text>
                        </Box>

                        <h3>Customer</h3>
                        <Box as="dl">
                            <Text as="dt" fontWeight="fontWeightSemibold">Email Address</Text>
                            <Text as="dd">{props.booking.originatingRequest.email_address}</Text>

                            <Text marginTop="space60" as="dt" fontWeight="fontWeightSemibold">Phone Number</Text>
                            <Text as="dd">{props.booking.originatingRequest.phone_number}</Text>

                            <Text marginTop="space60" as="dt" fontWeight="fontWeightSemibold">Membership Level</Text>
                            <Text as="dd">{props.booking.originatingRequest.membershipLevel}</Text>
                        </Box>

                    </Stack>

                </Flex>

                <Flex width="50%">
                    <Stack orientation="vertical" spacing="space40">
                        <h3>System Communication Log</h3>
                        <Box backgroundColor="colorBackground">
                            <ChatLog>
                                {messages.map((row, rowIndex, originalArray) => (
                                    <>
                                        {row.type === "twilio-new-message" && (
                                            <ChatMessage key={"message-" + rowIndex} variant="outbound">
                                                <ChatBubble><strong>{row.details.from}</strong>: {row.details.body}</ChatBubble>
                                                <ChatMessageMeta aria-label="said by you at 4:27 PM">
                                                    <ChatMessageMetaItem>
                                                        Queued @ {row.details.dateCreated} <br />
                                                        {row.sent && <>Sent @ {row.sent.timestamp}<br /></>}
                                                        {row.delivered && <>Delivered @ {row.delivered.timestamp}</>}
                                                    </ChatMessageMetaItem>
                                                </ChatMessageMeta>
                                            </ChatMessage>
                                        )}

                                        {row.type === "slack-new-message" &&
                                            (
                                                <>
                                                    <ChatEvent>
                                                        <strong>Booking Notification</strong> sent to <strong>Slack</strong> ・ {row.timestamp}
                                                    </ChatEvent>
                                                </>
                                            )}

                                        {row.type === "teams-new-message" &&
                                            (
                                                <>
                                                    <ChatEvent>
                                                        <strong>Booking Notification</strong> sent to <strong>Teams</strong> ・ {row.timestamp}
                                                    </ChatEvent>
                                                </>
                                            )}
                                    </>

                                )

                                )}
                            </ChatLog>
                        </Box>
                    </Stack>

                </Flex>
            </Flex>

        </>
    );
}